// 각 페이지에 표시할 알람에 대한 정보이다.
// window.alert() 는 이쁘지 않아서 커스텀해서 사용하게 되었습니다.
// 치명적인 오류, 기술적인 오류는 ERROR를,
// 사용자가 어떤 액션을 하였을때 안내 문구는 NOTICE를 띄워주세요.

export enum AlarmType {
  // 치명적인 에러
  ERROR,

  // 안내문
  NOTICE,
}

export interface AlarmState {
  // 알람을 표시할 지속시간
  duration: number;

  // 알람 타입
  alarmType: AlarmType;

  // 알람을 표시해야하는지.
  showAlarm: boolean;

  // 알람 내용
  content: string;

  // 알람 제목
  title?: string;
}
