import sendPostRequest from '../api';
import {
  CheckAuthenticationRequest,
  CheckAuthenticationResponse,
} from '../../proto/auth_api';

export const sendCheckAuthenticationRequest = sendPostRequest<
  CheckAuthenticationRequest,
  CheckAuthenticationResponse
>('/api/auth/check', CheckAuthenticationRequest, CheckAuthenticationResponse);
