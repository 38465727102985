import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ModalState, GlobalModalType } from '../states/modalState';

const initialState: ModalState = {
  renderedModal: undefined,
};

const modalSlice = createSlice({
  name: 'GLOBAL_MODAL',
  initialState,
  reducers: {
    setRenderedModal: (
      state,
      action: PayloadAction<GlobalModalType | undefined>,
    ) => {
      return {
        ...state,
        renderedModal: action.payload,
      };
    },
  },
});

export const { setRenderedModal } = modalSlice.actions;
export default modalSlice.reducer;
