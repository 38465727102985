import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlarmState, AlarmType } from '../states/alarmState';

const initialState: AlarmState = {
  duration: 0,
  alarmType: AlarmType.ERROR,
  showAlarm: false,
  content: '',
};

interface SetAlarmAction {
  duration: number;
  alarmType: AlarmType;
  content: string;
  title?: string;
}

const alarmSlice = createSlice({
  name: 'ALARM',
  initialState,
  reducers: {
    setAlarm: {
      reducer: (state, action: PayloadAction<SetAlarmAction>) => {
        return {
          ...state,
          duration: action.payload.duration,
          alarmType: action.payload.alarmType,
          showAlarm: true,
          content: action.payload.content,
          title: action.payload.title,
        };
      },
      prepare: (
        duration: number,
        alarmType: AlarmType,
        content: string,
        title?: string,
      ) => {
        return {
          payload: {
            duration,
            alarmType,
            content,
            title,
          },
        };
      },
    },
    offAlarm: (state) => {
      return {
        ...state,
        showAlarm: false,
      };
    },
  },
});

export const { offAlarm, setAlarm } = alarmSlice.actions;
export default alarmSlice.reducer;
