import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExternalUserInfo, LoginType, Role } from '../../proto/user';
import { UserLoginState } from '../states/userLoginState';
import { UpdateUserInfoRequest } from '../../proto/user_api';

const initialState: UserLoginState = {
  isLoggedIn: false,
  lastAuthCheck: 0,
  name: '',
  email: '',
  thumbnailImagePath: undefined,
  joinTime: Date.now(),
  lastLogin: Date.now(),
  totalNumVisitDays: 1,
  numContinuousLoginDays: 1,
  isAdmin: false,
  role: Role.UNRECOGNIZED,
  loginType: LoginType.UNRECOGNIZED,
  productCategoryList: [],
  isTest: false,
} satisfies UserLoginState;

interface UpdateUserInfoAction extends Omit<UpdateUserInfoRequest, 'email'> {
  numContinuousLoginDays?: number;
  thumbnailImagePath?: string;
  totalNumVisitDays?: number;
  isTest?: boolean;
}

const userLoginSlice = createSlice({
  name: 'USER_LOGIN',
  initialState,
  reducers: {
    loginUser: (state, action: PayloadAction<ExternalUserInfo>) => {
      return {
        ...state,
        isLoggedIn: true,
        lastAuthCheck: Date.now(),
        name: action.payload.name,
        email: action.payload.email,
        thumbnailImagePath: action.payload.thumbnailImagePath,
        numContinuousLoginDays: action.payload.numContinuousLoginDays,
        joinTime: action.payload.joinTime?.getTime() ?? Date.now(),
        lastLogin: action.payload.lastLogin?.getTime() ?? Date.now(),
        totalNumVisitDays: action.payload.totalNumVisitDays,
        isAdmin: action.payload.isAdmin,
        serviceCheckTime:
          action.payload.serviceCheckTime?.getTime() ?? undefined,
        privacyCheckTime:
          action.payload.privacyCheckTime?.getTime() ?? undefined,
        marketingCheckTime:
          action.payload.marketingCheckTime?.getTime() ?? undefined,
        role: action.payload.role,
        loginType: action.payload.loginType,
        productCategoryList: action.payload.productCategoryList,
        isTest: action.payload.isTest,
      } satisfies UserLoginState;
    },
    updateUser: (state, action: PayloadAction<UpdateUserInfoAction>) => {
      return {
        ...state,
        name: action.payload.name ?? state.name,
        numContinuousLoginDays:
          action.payload.numContinuousLoginDays ?? state.numContinuousLoginDays,
        thumbnailImagePath:
          action.payload.thumbnailImagePath ?? state.thumbnailImagePath,
        totalNumVisitDays:
          action.payload.totalNumVisitDays ?? state.totalNumVisitDays,
        serviceCheckTime: action.payload.isServiceChecked
          ? Date.now()
          : action.payload.isServiceChecked == false
            ? undefined
            : state.serviceCheckTime,
        privacyCheckTime: action.payload.isPrivacyChecked
          ? Date.now()
          : action.payload.isPrivacyChecked == false
            ? undefined
            : state.privacyCheckTime,
        marketingCheckTime: action.payload.isMarketingChecked
          ? Date.now()
          : action.payload.isMarketingChecked == false
            ? undefined
            : state.marketingCheckTime,
        role: action.payload.role ?? state.role,
        productCategoryList:
          action.payload.productCategoryList ?? state.productCategoryList,
        isTest: action.payload.isTest ?? state.isTest,
      } satisfies UserLoginState;
    },
    logoutUser() {
      return initialState;
    },
    setLastAuthCheckToCurrent(state) {
      return {
        ...state,
        lastAuthCheck: Date.now(),
      } satisfies UserLoginState;
    },
  },
});

export const { loginUser, updateUser, logoutUser, setLastAuthCheckToCurrent } =
  userLoginSlice.actions;
export default userLoginSlice.reducer;
