import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCombinedStateSelector } from '../redux/hook';
import { ExternalUserInfo } from '../proto/user';
import { CheckAuthenticationResponse } from '../proto/auth_api';
import {
  logoutUser,
  setLastAuthCheckToCurrent,
  updateUser,
} from '../redux/slices/userLoginSlice';
import { isDevMode } from '../utils/detectMode';
import { sendCheckAuthenticationRequest } from '../api/auth/check';
import { useNavigate } from 'react-router-dom';
import { Path, getPath } from '../components/Header/path';
import { UpdatableInfoType } from './type';

const useCheckAuthentication: () => UpdatableInfoType<ExternalUserInfo> & {
  isLoggedIn: boolean;
} = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useCombinedStateSelector(
    (state) => state.userLoginState.isLoggedIn,
  );

  const [isDataFetched, setIsDataFetched] = useState(false);

  const [userInfo, setUserInfo] = useState<ExternalUserInfo>(
    ExternalUserInfo.create(),
  );

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const handleCheckAuthenticationResponse = (
      response: CheckAuthenticationResponse,
    ) => {
      if (response.userInfo) {
        dispatch(setLastAuthCheckToCurrent());
        dispatch(updateUser(response.userInfo));
        setUserInfo(response.userInfo);
      } else {
        dispatch(logoutUser());
      }
    };

    if (isDevMode()) {
      setIsDataFetched(true);

      return;
    }

    sendCheckAuthenticationRequest({}, () => {
      /* do nothing */
    })
      .then(handleCheckAuthenticationResponse)
      .then(() => setIsDataFetched(true));
  }, []);

  return {
    isLoggedIn,
    isDataFetched,
    value: userInfo,
    updateValue: setUserInfo,
  };
};

export const useCheckAuthenticationWithExpireTime = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lastAuthCheckTimestamp = useCombinedStateSelector((state) => {
    return state.userLoginState.lastAuthCheck;
  });

  const isLoggedIn = useCombinedStateSelector(
    (state) => state.userLoginState.isLoggedIn,
  );

  const MIN_AUTH_CHECK_TIMESPAN = 60 * 60 * 1000; // 1hr.

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const watiTime =
      MIN_AUTH_CHECK_TIMESPAN - (Date.now() - lastAuthCheckTimestamp) > 0
        ? MIN_AUTH_CHECK_TIMESPAN - (Date.now() - lastAuthCheckTimestamp)
        : 0;

    const checkAuthenticationTimer = setTimeout(() => {
      sendCheckAuthenticationRequest({}, () => {
        /*do noting */
      }).then((response) => {
        if (response.userInfo) {
          dispatch(setLastAuthCheckToCurrent());
          dispatch(
            updateUser({
              numContinuousLoginDays: response.userInfo.numContinuousLoginDays,
              productCategoryList: response.userInfo.productCategoryList,
            }),
          );
        } else {
          dispatch(logoutUser());
          navigate(getPath(Path.HOME));
        }
      });
    }, watiTime);

    return () => clearInterval(checkAuthenticationTimer);
  }, [lastAuthCheckTimestamp, isLoggedIn]);
};

export default useCheckAuthentication;
