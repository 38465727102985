import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userLoginSlice from './slices/userLoginSlice';
import alarmSlice from './slices/alarmSlice';
import loadingSlice from './slices/loadingSlice';
import modalSlice from './slices/modalSlice';

const persistConfig = {
  key: 'adglow-v1.03',

  // Local storage
  storage,

  whitelist: ['userLoginState'],
};

export const rootReducer = persistReducer(
  persistConfig,
  combineReducers({
    userLoginState: userLoginSlice,
    alarmState: alarmSlice,
    loadingState: loadingSlice,
    modalState: modalSlice,
  }),
);
