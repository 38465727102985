import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingState } from '../states/loadingState';

const initialState: LoadingState = {
  showLoading: false,
  message: '',
};

const loadingSlice = createSlice({
  name: 'LOADING',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<string>) => {
      return {
        showLoading: true,
        message: action.payload,
      };
    },
    offLoading: () => {
      return {
        message: '',
        showLoading: false,
      };
    },
  },
});

export const { setLoading, offLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
