import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { UserLoginState } from './states/userLoginState';
import { AlarmState } from './states/alarmState';
import { LoadingState } from './states/loadingState';
import { ModalState } from './states/modalState';

export interface CombinedState {
  userLoginState: UserLoginState;
  alarmState: AlarmState;
  loadingState: LoadingState;
  modalState: ModalState;
}

export const useCombinedStateSelector: TypedUseSelectorHook<CombinedState> =
  useSelector;
